<template>
  <div class="inner-section">
    <body-card>
          <template>
            <b-overlay :show="loading">
                <b-row>
                    <b-col v-if="profile.professional_info.org_id">
                        <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="profile.professional_info.org_type_id === 1 ? orgMoc(profile.professional_info.org_id) : 10">
                            <div class="titleDiv">
                            </div>
                        </list-report-head>
                    </b-col>
                </b-row>
                <b-col lg="12">
                <b-row>
                <div class="card card w-100 text-center">
                <div class="card-inside-title font-weight-bold text-capitalize p-1 bg-light text-black">
                  <h5 class="font-weight-bold text-capitalize text-center bg-light p-1 badge-light" style="color: green!important;font-size: 20px!important;"> {{ $t('globalTrans.details') }}</h5>
                </div>
                </div>
                </b-row>
                <b-row>
                  <b-col lg="12" sm="12" class="p-0">
                    <b-table-simple bordered striped>
                      <!-- <b-thead>
                          <b-tr>
                            <b-th style="" scope="col">{{ $t('elearning_iabm.registration_for') }}</b-th>
                            <b-td style="" class="line-height-five" scope="col">{{ ($i18n.locale=='bn') ? profile.user_type_name_bn : profile.user_type_name }}</b-td>
                          </b-tr>
                        </b-thead> -->
                        <b-tbody>
                          <b-tr>
                            <b-th style="" class="w-50 line-height-five" scope="row">{{ $t('globalTrans.name') }}</b-th>
                            <b-td style="" class="w-50 line-height-five">{{ ($i18n.locale=='bn') ? profile.name_bn : profile.name }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row">{{ $t('globalTrans.nid') }}</b-th>
                            <b-td style="">
                              <slot v-if="profile.nid">
                              {{ $n(profile.nid, { useGrouping: false }) }}
                              </slot>
                              </b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row">{{ $t('globalTrans.dob') }}</b-th>
                            <b-td style="">{{ profile.date_of_birth | dateFormat }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row">{{ $t('globalTrans.mobile') }}</b-th>
                            <b-td style="">{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(profile.mobile, { useGrouping: false }) }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style=" " scope="row">{{ $t('globalTrans.email') }}</b-th>
                            <b-td style=" ">{{ ($i18n.locale=='bn') ? profile.email : profile.email }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row">{{ $t('globalTrans.gender') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.gender_name_bn : profile.gender_name }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style=" " scope="row">{{ $t('externalTraining.mat_status') }}</b-th>
                            <b-td style=" ">{{ ($i18n.locale=='bn') ? profile.marital_name_bn : profile.marital_name }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row">{{ $t('externalTraining.blood_group') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.blood_name_bn : profile.blood_name }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row">{{ $t('externalTraining.religion') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.religion_name_bn : profile.religion_name }}</b-td>
                          </b-tr>
                        </b-tbody>
                    </b-table-simple>
                  </b-col>
              </b-row>
                              <b-row>
                <div class="card card w-100 text-center">
                <div class="card-inside-title font-weight-bold text-capitalize p-1 bg-light text-black">
                <h5 class="font-weight-bold text-capitalize text-center bg-light p-1 badge-light" style="color: green!important;font-size: 20px!important;">   {{ $t('externalTraining.professional_info') }}</h5>
                </div>
                </div>
                </b-row>
                <b-row>
                  <b-col lg="12" sm="12" class="p-0">
                    <b-table-simple bordered striped>
                      <b-thead>
                          <b-tr>
                            <b-th style="" scope="col">{{ $t('globalTrans.organization') }}</b-th>
                            <b-td style="" scope="col">{{ ($i18n.locale=='bn') ? profile.org_name_bn : profile.org_name }}</b-td>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr>
                            <b-th style="" class="w-50" scope="row">{{ $t('globalTrans.designation') }}</b-th>
                            <b-td style="" class="w-50">{{ ($i18n.locale=='bn') ? profile.designation_name_bn : profile.designation_name }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row">{{ $t('elearning_iabm.job_type') }}</b-th>
                            <b-td style="">
                              <slot v-if="profile.professional_info.job_type_id">
                                {{ ($i18n.locale=='bn') ? profile.job_name_bn : profile.job_name }}
                              </slot>
                              </b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row">{{ $t('elearning_iabm.batch') }}</b-th>
                            <b-td style="">
                              <slot v-if="profile.professional_info.batch">
                                {{ profile.professional_info.batch }}
                              </slot>
                              </b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row">{{ $t('elearning_iabm.emergency_contact') }}</b-th>
                            <b-td style="">
                              <slot v-if="profile.professional_info.emergency_contact">
                                {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(profile.professional_info.emergency_contact, { useGrouping: false }) }}
                              </slot>
                              </b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row">{{ $t('globalTrans.service_id') }}</b-th>
                            <b-td style="">
                              <slot v-if="profile.professional_info.service_id">
                                {{ $n(profile.professional_info.service_id, { useGrouping: false }) }}
                              </slot>
                              </b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row">{{ $t('globalTrans.pay_grade') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.grade_name_bn : profile.grade_name }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row">{{ $t('globalTrans.designation') }}</b-th>
                            <b-td style="">{{ profile.professional_info.controlling_authority }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row">{{ $t('globalTrans.mobile') }}</b-th>
                            <b-td style="">{{ profile.professional_info.telephone }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row">{{ $t('globalTrans.email') }}</b-th>
                            <b-td style="">{{ profile.professional_info.email }}</b-td>
                          </b-tr>
                        <b-tr>
                            <b-th style="" scope="row">{{ $t('externalTraining.office_mail') }}</b-th>
                            <b-td style="">{{ profile.professional_info.office_mail_address }}</b-td>
                          </b-tr>
                        </b-tbody>
                    </b-table-simple>
                  </b-col>
              </b-row>
                <b-row>
                <div class="card card w-100 text-center">
                <div class="card-inside-title font-weight-bold text-capitalize p-1 bg-light text-black">
                <h5 class="font-weight-bold text-capitalize text-center bg-light p-1 badge-light" style="color: green!important;font-size: 20px!important;">   {{ $t('externalTraining.academic_info') }} </h5>
                </div>
                </div>
                </b-row>
                <b-row>
                  <b-col lg="12" sm="12" class="p-0">
                    <b-table-simple bordered striped>
                      <b-thead>
                          <b-tr>
                            <b-th style="" class="w-50" scope="row">{{ $t('externalTraining.highest_degree') }}</b-th>
                            <b-td style="" class="w-50">
                              <slot v-if="profile.name_of_highest_degree">
                                {{ profile.name_of_highest_degree }}
                              </slot>
                              </b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row" class="w-50">{{ $t('externalTraining.board_university') }}</b-th>
                            <b-td style="" class="w-50">
                              <slot v-if="profile.board">
                                {{ profile.board }}
                              </slot>
                            </b-td>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                        </b-tbody>
                    </b-table-simple>
                  </b-col>
              </b-row>
                <b-row>
                <div class="card card w-100 text-center">
                <div class="card-inside-title font-weight-bold text-capitalize p-1 bg-light text-black">
                  <h5 class="font-weight-bold text-capitalize text-center bg-light p-1 badge-light" style="color: green!important;font-size: 20px!important;">    {{ $t('externalTraining.present_address') }}</h5>
                </div>
                </div>
                </b-row>
                <b-row>
                  <b-col lg="12" sm="12" class="p-0">
                    <b-table-simple bordered striped>
                      <b-thead>
                          <b-tr>
                            <b-th style="" scope="col">{{ $t('globalTrans.area_type') }}</b-th>
                            <b-th style="" scope="col">{{ ($i18n.locale=='bn') ? profile.area_name_bn : profile.area_name }}</b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr>
                            <b-th style="" class="w-50" scope="row">{{ $t('globalTrans.division') }}</b-th>
                            <b-td style="" class="w-50">{{ ($i18n.locale=='bn') ? profile.division_name_bn : profile.division_name }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row">{{ $t('globalTrans.district') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.district_name_bn : profile.district_name }}</b-td>
                          </b-tr>
                          <b-tr v-if="profile.address.pre_area_type_id == 3||2">
                            <b-th style="" scope="row">{{ $t('globalTrans.upazila') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.upazilla_name_bn : profile.upazilla_name }}</b-td>
                          </b-tr>
                          <b-tr v-if="profile.address.pre_area_type_id == 3">
                            <b-th style="" scope="row">{{ $t('globalTrans.union') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.union_name_bn : profile.union_name }}</b-td>
                          </b-tr>
                          <b-tr v-if="profile.address.pre_area_type_id == 1">
                            <b-th style="" scope="row">{{ $t('globalTrans.city_corporation') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.cityCorporation_name_bn : profile.cityCorporation_name }}</b-td>
                          </b-tr>
                          <b-tr v-if="profile.address.pre_area_type_id == 3||2">
                            <b-th style="" scope="row">{{ $t('globalTrans.ward') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.per_ward_name_bn : profile.per_ward_name }}</b-td>
                          </b-tr>
                          <b-tr v-if="profile.address.pre_area_type_id == 2">
                            <b-th style="" scope="row">{{ $t('globalTrans.pauroshoba') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.per_pauroshoba_name_bn : profile.per_pauroshoba_name }}</b-td>
                          </b-tr>
                        </b-tbody>
                    </b-table-simple>
                  </b-col>
              </b-row>
              <b-row>
                <div class="card card w-100 text-center">
                <div class="card-inside-title font-weight-bold text-capitalize p-1 bg-light text-black">
                <h5 class="font-weight-bold text-capitalize text-center bg-light p-1 badge-light" style="color: green!important;font-size: 20px!important;">   {{ $t('externalTraining.parmanent_address') }}</h5>
                </div>
                </div>
                </b-row>
                <b-row>
                  <b-col lg="12" sm="12" class="p-0">
                    <b-table-simple bordered striped>
                      <b-thead>
                          <b-tr>
                            <b-th style="" scope="col">{{ $t('globalTrans.area_type') }}</b-th>
                            <b-td style="" scope="col">{{ ($i18n.locale=='bn') ? profile.per_area_name_bn : profile.per_area_name }}</b-td>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr>
                            <b-th style="" class="w-50" scope="row">{{ $t('globalTrans.division') }}</b-th>
                            <b-td style="" class="w-50">{{ ($i18n.locale=='bn') ? profile.per_division_name_bn : profile.per_division_name }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th style="" scope="row">{{ $t('globalTrans.district') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.per_district_name_bn : profile.per_district_name }}</b-td>
                          </b-tr>
                          <b-tr v-if="profile.address.per_area_type_id == 3||2">
                            <b-th style="" scope="row">{{ $t('globalTrans.upazilla')}} </b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.per_upazilla_name_bn : profile.per_upazilla_name }}</b-td>
                          </b-tr>
                          <b-tr v-if="profile.address.per_area_type_id == 3">
                            <b-th style="" scope="row">{{ $t('globalTrans.union') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.per_union_name_bn : profile.per_union_name }}</b-td>
                          </b-tr>
                        <b-tr v-if="profile.address.per_area_type_id == 1">
                            <b-th style="" scope="row">{{ $t('globalTrans.city_corporation') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.per_cityCorporation_name_bn : profile.per_cityCorporation_name }}</b-td>
                          </b-tr>
                        <b-tr v-if="profile.address.per_area_type_id == 3||2">
                            <b-th style="" scope="row">{{ $t('globalTrans.ward') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.per_ward_name_bn : profile.per_ward_name }}</b-td>
                          </b-tr>
                          <b-tr v-if="profile.address.per_area_type_id == 2">
                            <b-th style="" scope="row">{{ $t('globalTrans.pauroshoba') }}</b-th>
                            <b-td style="">{{ ($i18n.locale=='bn') ? profile.per_pauroshoba_name_bn : profile.per_pauroshoba_name }}</b-td>
                          </b-tr>
                        </b-tbody>
                    </b-table-simple>
                  </b-col>
              </b-row>
              </b-col>
            </b-overlay>
          </template>
    </body-card>
  </div>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ExternalListReportHead.vue'
import ExportPdf from './export_pdf_details_profile'
import { profileShow } from '../../api/routes'
export default {
    components: {
        ListReportHead
    },
  created () {
    this.loadData()
  },
  data () {
    return {
      load: false,
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      profile: {
            registration_for: '',
            name: '',
            name_bn: '',
            datee_of_birth: '',
            email: '',
            nid: '',
            mobile: '',
            gender: '',
            marital_status: '',
            blood_group: '',
            religion: '',
            name_of_highest_degree: '',
            board: '',
            professional_info: {
                org_id: 0,
                designation_id: 0,
                grade_id: 0,
                service_id: '',
                controlling_authority: '',
                telephone: '',
                email: '',
                office_mail_address: ''
            },
            address: {
                pre_area_type_id: 0,
                pre_division_id: 0,
                pre_district_id: 0,
                pre_upazilla_id: 0,
                pre_ward_id: '',
                pre_union_id: '',
                pre_city_corporation_id: '',
                pre_pauroshoba_id: '',
                same_as_pre_address: '',
                per_area_type_id: 0,
                per_division_id: 0,
                per_district_id: 0,
                per_upazilla_id: 0,
                per_city_corporation_id: '',
                per_pauroshoba_id: '',
                per_ward_id: '',
                per_union_id: ''
            },
            is_draft: 1,
            status: 1
        }
    }
  },
  computed: {
        loading () {
            return this.$store.state.commonObj.loading
        },
    userTypeList () {
        const userList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Trainer' : 'প্রশিক্ষক', text_en: 'Trainer', text_bn: 'প্রশিক্ষক' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী', text_en: 'Trainee', text_bn: 'প্রশিক্ষণার্থী' }
        ]
        return userList
    },
    genderList () {
    const genderList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Male' : 'পুরুষ', text_en: 'Male', text_bn: 'পুরুষ' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Female' : 'মহিলা', text_en: 'Female', text_bn: 'মহিলা' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Others' : 'অন্যান্য', text_en: 'Others', text_bn: 'অন্যান্য' }
    ]
    return genderList
    },
    maritalList () {
    const status = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Married' : 'বিবাহিত', text_en: 'Married', text_bn: 'বিবাহিত' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Unmarried' : 'অবিবাহিত', text_en: 'Unmarried', text_bn: 'অবিবাহিত' }
    ]
    return status
    },
    bloodGroupList () {
    const bloodGroupList = [
        { value: 1, text: 'A+', text_bn: 'এ+' },
        { value: 2, text: 'A-', text_bn: 'এ-' },
        { value: 3, text: 'B+', text_bn: 'বি+' },
        { value: 4, text: 'B-', text_bn: 'বি-' },
        { value: 5, text: 'O+', text_bn: 'ও+' },
        { value: 6, text: 'O-', text_bn: 'ও-' },
        { value: 7, text: 'AB+', text_bn: 'এবি+' },
        { value: 8, text: 'AB-', text_bn: 'এবি-' }
    ]
    return bloodGroupList
    },
    religionList () {
    const religionList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Muslim' : 'মুসলিম', text_en: 'Muslim', text_bn: 'মুসলিম' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Hindu' : 'হিন্দু', text_en: 'Hindu', text_bn: 'হিন্দু' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Christian' : 'খ্রিস্টান', text_en: 'Christian', text_bn: 'খ্রিস্টান' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Buddhist' : 'বৌদ্ধ ', text_en: 'Buddhist', text_bn: 'বৌদ্ধ' },
        { value: 5, text: this.$i18n.locale === 'en' ? 'Others' : 'অন্যান্য', text_en: 'Others', text_bn: 'অন্যান্য' }
    ]
    return religionList
    },
    fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    trainerEvaluationList: function () {
    return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
    },
    formTitle () {
        return (this.editItemId === 0) ? this.$t('elearning_config.registration') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.registration') + ' ' + this.$t('globalTrans.update')
    },
    columns () {
        const labels = [
            { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
            { label: this.$t('elearning_iabm.registration_for'), class: 'text-left' },
            { label: this.$t('globalTrans.name'), class: 'text-left' },
            { label: this.$t('globalTrans.email'), class: 'text-left' },
            { label: this.$t('globalTrans.mobile'), class: 'text-left' },
            { label: this.$t('globalTrans.dob'), class: 'text-left' },
            { label: this.$t('globalTrans.nid'), class: 'text-left' },
            // { label: this.$t('globalTrans.status'), class: 'text-center' },
            { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
            keys = [
            { key: 'index' },
            { key: 'user_type_name_bn' },
            { key: 'name_bn' },
            { key: 'email' },
            { key: 'mobile' },
            { key: 'date_of_birth' },
            { key: 'nid' },
            // { key: 'status' },
            { key: 'action' }
            ]
        } else {
            keys = [
            { key: 'index' },
            { key: 'user_type_name' },
            { key: 'name' },
            { key: 'email' },
            { key: 'mobile' },
            { key: 'date_of_birth' },
            { key: 'nid' },
            // { key: 'status' },
            { key: 'action' }
            ]
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
    }
},
  methods: {
    orgMoc (orgId) {
      const orgData = this.$store.state.TrainingElearning.commonObj.nominatedOrgList.find(item => item.value === orgId)
      return orgData.org_id
    },
    loadData () {
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(trainingElearningServiceBaseUrl, profileShow).then(response => {
            if (response.success) {
                this.profile = this.getCustomDataList(response.data)
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            }
        })
    },
    getCustomDataList (item) {
            const userTypeObj = this.userTypeList.find(userType => userType.value === item.registration_for)
            if (typeof userTypeObj !== 'undefined') {
              item.user_type_name = userTypeObj.text_en
              item.user_type_name_bn = userTypeObj.text_bn
            } else {
              item.user_type_name = ''
              item.user_type_name_bn = ''
            }
            const orgObj = this.$store.state.CommonService.commonObj.orgProfileList.find(org => org.value === parseInt(item.professional_info.org_id))
            if (typeof orgObj !== 'undefined') {
              item.org_name = orgObj.text_en
              item.org_name_bn = orgObj.text_bn
            } else {
              item.org_name = ''
              item.org_name_bn = ''
            }
            const jobTypeObj = this.$store.state.ExternalUserService.trainingPanel.commonObj.jobTypeList.find(job => parseInt(job.value) === parseInt(item.professional_info.job_type_id))
            if (typeof jobTypeObj !== 'undefined') {
              item.job_name = jobTypeObj.text_en
              item.job_name_bn = jobTypeObj.text_bn
            } else {
              item.job_name = ''
              item.job_name_bn = ''
            }
            const designationObj = this.$store.state.CommonService.commonObj.designationList.find(designation => designation.value === item.professional_info.designation_id)
            if (typeof designationObj !== 'undefined') {
              item.designation_name = designationObj.text_en
              item.designation_name_bn = designationObj.text_bn
            } else {
              item.designation_name = ''
              item.designation_name_bn = ''
            }
            const gradeObj = this.$store.state.commonObj.gradeList.find(grade => grade.value === parseInt(item.professional_info.grade_id))
            if (typeof gradeObj !== 'undefined') {
              item.grade_name = gradeObj.text_en
              item.grade_name_bn = gradeObj.text_bn
            } else {
              item.grade_name = ''
              item.grade_name_bn = ''
            }
            const areaObj = this.$store.state.commonObj.areaTypeList.find(area => area.id === parseInt(item.address.pre_area_type_id))
            if (typeof areaObj !== 'undefined') {
              item.area_name = areaObj.name
              item.area_name_bn = areaObj.name_bn
            } else {
              item.area_name = ''
              item.area_name_bn = ''
            }
            const perareaObj = this.$store.state.commonObj.areaTypeList.find(area => area.id === parseInt(item.address.per_area_type_id))
            if (typeof perareaObj !== 'undefined') {
              item.per_area_name = perareaObj.name
              item.per_area_name_bn = perareaObj.name_bn
            } else {
              item.per_area_name = ''
              item.per_area_name_bn = ''
            }
            const divisionObj = this.$store.state.CommonService.commonObj.divisionList.find(division => division.value === parseInt(item.address.pre_division_id))
            if (typeof divisionObj !== 'undefined') {
              item.division_name = divisionObj.text_en
              item.division_name_bn = divisionObj.text_bn
            } else {
              item.division_name = ''
              item.division_name_bn = ''
            }
            const perdivisionObj = this.$store.state.CommonService.commonObj.divisionList.find(division => division.value === parseInt(item.address.per_division_id))
            if (typeof perdivisionObj !== 'undefined') {
              item.per_division_name = perdivisionObj.text_en
              item.per_division_name_bn = perdivisionObj.text_bn
            } else {
              item.per_division_name = ''
              item.per_division_name_bn = ''
            }
            const districtObj = this.$store.state.CommonService.commonObj.districtList.find(district => district.value === parseInt(item.address.pre_district_id))
            if (typeof divisionObj !== 'undefined') {
              item.district_name = districtObj.text_en
              item.district_name_bn = districtObj.text_bn
            } else {
              item.district_name = ''
              item.district_name_bn = ''
            }
            const perdistrictObj = this.$store.state.CommonService.commonObj.districtList.find(district => district.value === parseInt(item.address.per_district_id))
            if (typeof perdivisionObj !== 'undefined') {
              item.per_district_name = perdistrictObj.text_en
              item.per_district_name_bn = perdistrictObj.text_bn
            } else {
              item.per_district_name = ''
              item.per_district_name_bn = ''
            }
            const upazillaObj = this.$store.state.CommonService.commonObj.upazilaList.find(upazilla => upazilla.value === parseInt(item.address.pre_upazilla_id))
            if (typeof upazillaObj !== 'undefined') {
              item.upazilla_name = upazillaObj.text_en
              item.upazilla_name_bn = upazillaObj.text_bn
            } else {
              item.upazilla_name = ''
              item.upazilla_name_bn = ''
            }
            const perupazillaObj = this.$store.state.CommonService.commonObj.upazilaList.find(perupazilla => perupazilla.value === parseInt(item.address.per_upazilla_id))
            if (typeof perupazillaObj !== 'undefined') {
              item.per_upazilla_name = perupazillaObj.text_en
              item.per_upazilla_name_bn = perupazillaObj.text_bn
            } else {
              item.per_upazilla_name = ''
              item.per_upazilla_name_bn = ''
            }
            const cityCorporationObj = this.$store.state.CommonService.commonObj.cityCorporationList.find(cityCorporation => cityCorporation.value === parseInt(item.address.pre_city_corporation_id))
            if (typeof cityCorporationObj !== 'undefined') {
              item.cityCorporation_name = cityCorporationObj.text_en
              item.cityCorporation_name_bn = cityCorporationObj.text_bn
            } else {
              item.cityCorporation_name = ''
              item.cityCorporation_name_bn = ''
            }
            const percityCorporationObj = this.$store.state.CommonService.commonObj.cityCorporationList.find(cityCorporation => cityCorporation.value === parseInt(item.address.pre_city_corporation_id))
            if (typeof percityCorporationObj !== 'undefined') {
              item.per_cityCorporation_name = percityCorporationObj.text_en
              item.per_cityCorporation_name_bn = percityCorporationObj.text_bn
            } else {
              item.per_cityCorporation_name = ''
              item.per_cityCorporation_name_bn = ''
            }
            const unionObj = this.$store.state.CommonService.commonObj.unionList.find(union => union.value === parseInt(item.address.pre_union_id))
            if (typeof unionObj !== 'undefined') {
              item.union_name = unionObj.text_en
              item.union_name_bn = unionObj.text_bn
            } else {
              item.union_name = ''
              item.union_name_bn = ''
            }
            const perunionObj = this.$store.state.CommonService.commonObj.unionList.find(union => union.value === parseInt(item.address.per_union_id))
            if (typeof perunionObj !== 'undefined') {
              item.per_union_name = perunionObj.text_en
              item.per_union_name_bn = perunionObj.text_bn
            } else {
              item.per_union_name = ''
              item.per_union_name_bn = ''
            }
            const wardObj = this.$store.state.CommonService.commonObj.wardList.find(ward => ward.value === parseInt(item.address.pre_ward_id))
            if (typeof wardObj !== 'undefined') {
              item.ward_name = wardObj.text_en
              item.ward_name_bn = wardObj.text_bn
            } else {
              item.ward_name = ''
              item.ward_name_bn = ''
            }
            const perwardObj = this.$store.state.CommonService.commonObj.wardList.find(ward => ward.value === parseInt(item.address.per_ward_id))
            if (typeof perwardObj !== 'undefined') {
              item.per_ward_name = perwardObj.text_en
              item.per_ward_name_bn = perwardObj.text_bn
            } else {
              item.per_ward_name = ''
              item.per_ward_name_bn = ''
            }
            const pauroshobaObj = this.$store.state.CommonService.commonObj.municipalityList.find(per => per.value === parseInt(item.address.pre_pauroshoba_id))
            if (typeof pauroshobaObj !== 'undefined') {
              item.pauroshoba_name = pauroshobaObj.text_en
              item.pauroshoba_name_bn = pauroshobaObj.text_bn
            } else {
              item.pauroshoba_name = ''
              item.pauroshoba_name_bn = ''
            }
            const perpauroshobaObj = this.$store.state.CommonService.commonObj.municipalityList.find(per => per.value === parseInt(item.address.per_pauroshoba_id))
            if (typeof perpauroshobaObj !== 'undefined') {
              item.per_pauroshoba_name = perpauroshobaObj.text_en
              item.per_pauroshoba_name_bn = perpauroshobaObj.text_bn
            } else {
              item.per_pauroshoba_name = ''
              item.per_pauroshoba_name_bn = ''
            }
            const genderObj = this.genderList.find(per => per.value === item.gender)
            if (typeof genderObj !== 'undefined') {
              item.gender_name = genderObj.text_en
              item.gender_name_bn = genderObj.text_bn
            } else {
              item.gender_name = ''
              item.gender_name_bn = ''
            }
            const maritalObj = this.maritalList.find(per => per.value === item.marital_status)
            if (typeof maritalObj !== 'undefined') {
              item.marital_name = maritalObj.text_en
              item.marital_name_bn = maritalObj.text_bn
            } else {
              item.marital_name = ''
              item.marital_name_bn = ''
            }
            const bloodObj = this.bloodGroupList.find(blood => blood.value === item.blood_group)
            if (typeof bloodObj !== 'undefined') {
              item.blood_name = bloodObj.text
              item.blood_name_bn = bloodObj.text_bn
            } else {
              item.blood_name = ''
              item.blood_name_bn = ''
            }
            const religionObj = this.religionList.find(religion => religion.value === item.religion)
            if (typeof religionObj !== 'undefined') {
              item.religion_name = religionObj.text_en
              item.religion_name_bn = religionObj.text_bn
            } else {
              item.religion_name = ''
              item.religion_name_bn = ''
            }
          return item
        },
        pdfExport () {
            const reportTitle = this.$i18n.locale === 'en' ? 'Profile Info Details' : 'প্রোফাইলের তথ্য বিবরণ'
            ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', this.profile.professional_info.org_type_id === 1 ? this.orgMoc(this.profile.professional_info.org_id) : 10, reportTitle, this.profile, this)
        }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
