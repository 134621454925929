<template>
    <div class="inner-section">
        <card>
            <template v-slot:searchHeaderTitle>
                <div class="text-center">
                    <h4 class="card-title">{{ $t('externalTraining.profile_info') }}</h4>
                </div>
            </template>
            <!-- <template v-slot:searchHeaderAction>
                <b-button  class="btn-add mr-2" :disabled="overlay ? 'true' : false" title="View Details" v-b-modal.modal-5 variant="primary" size="sm"><i class="ri-eye-line m-0 "></i> {{ $t('globalTrans.details') }}</b-button>
            </template> -->
            <template v-slot:searchBody>
                <b-row v-if="overlay" variant="default" show>
                    <b-col>
                        <h4 class="text-center mt-2 mb-4 text-info"><i class="ri-alert-fill mr-2"></i>{{ $t('externalTraining.dashboard_message') }}</h4>
                    </b-col>
                </b-row>
                <b-overlay :show="loading">
                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form id="form" @submit.prevent="handleSubmit(register)" @reset.prevent="reset">
                            <body-card>
                                <template v-slot:headerTitle>
                                    <h4 class="card-title">
                                        {{$t('globalTrans.personal_information')}}
                                    </h4>
                                </template>
                                <template v-slot:body>
                                    <b-row>
                                        <b-col sm="4">
                                            <ValidationProvider name="Name" vid='name' rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="name"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('globalTrans.name_en')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        readonly
                                                        id="name"
                                                        v-model="profileInfo.name"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="Name (bn)" vid='name_bn' rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="name_bn"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('globalTrans.name_bn')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        readonly
                                                        id="name_bn"
                                                        v-model="profileInfo.name_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="Photo" vid="image" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    :label="$t('globalTrans.photo')"
                                                    label-for="image"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{$t('globalTrans.photo')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-file
                                                        id="image"
                                                        accept="image/*"
                                                        v-model="profileInfo.image_file"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-file>
                                                    <!-- <b-form-file
                                                        id="image"
                                                        accept="image/*"
                                                        v-model="profileInfo.image_file"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        v-on:change="handleLeftPhoto"
                                                    ></b-form-file> -->
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                        </b-col>
                                        <b-col lg="6" sm="12">
                                        <ValidationProvider name="Signature" vid="signature" :rules="profileInfo.id ? '' : 'required'">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                :label="$t('globalTrans.signature')"
                                                label-for="signature"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('globalTrans.signature')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-file
                                                    id="signature"
                                                    accept="image/*"
                                                    v-model="profileInfo.signature_file"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-file>
                                                <!-- <b-form-file
                                                    id="signature"
                                                    accept="image/*"
                                                    v-model="profileInfo.signature_file"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-on:change="handleRightPhoto"
                                                ></b-form-file> -->
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="NID" vid='nid' rules="">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="nid"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('globalTrans.nid')}}
                                                    </template>
                                                    <b-form-input
                                                        type="number"
                                                        id="nid"
                                                        v-model="profileInfo.nid"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="Date Of Birth" vid="date_of_birth" rules="required">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="date_of_birth"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                {{$t('globalTrans.dob')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                class="fromDate"
                                                v-model="profileInfo.date_of_birth"
                                                :placeholder="$t('globalTrans.select_date')"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="Mobile" vid='mobile' rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="mobile"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('globalTrans.phone_no')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        id="mobile"
                                                        v-model="profileInfo.mobile"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="Email" vid='email' rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="email"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('externalTraining.email')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        id="email"
                                                        v-model="profileInfo.email"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="Gender" vid="gender" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="gender"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('externalTraining.gender')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="profileInfo.gender"
                                                        :options="genderList"
                                                        id="gender"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="Marital Status" vid="marital_status" rules="">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="marital_status"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('externalTraining.mat_status')}}
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="profileInfo.marital_status"
                                                        :options="maritalList"
                                                        id="marital_status"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="Blood Group" vid="blood_group" rules="">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="blood_group"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('externalTraining.blood_group')}}
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="profileInfo.blood_group"
                                                        :options="bloodGroupList"
                                                        id="blood_group"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="Religion" vid="religion" rules="">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="religion"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('externalTraining.religion')}}
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="profileInfo.religion"
                                                        :options="religionList"
                                                        id="religion"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="Emergency Contact" vid='emergency_contact' rules="required|min:11|max:11">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="emergency_contact"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                {{ $t('elearning_iabm.emergency_contact')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    id="emergency_contact"
                                                    v-model="profileInfo.emergency_contact"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                </template>
                            </body-card>
                            <body-card>
                                <template v-slot:headerTitle>
                                    <h4 class="card-title">{{$t('externalTraining.professional_info')}}</h4>
                                </template>
                                <template v-slot:body>
                                    <b-row>
                                        <b-col sm="4">
                                            <ValidationProvider name="Organization Type" vid="org_type_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="org_type_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('elearning_config.organization_type')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <!-- <b-form-select
                                                        plain
                                                        v-model="profileInfo.professional_info.org_type_id"
                                                        :options="orgTypeList"
                                                        id="org_type_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select> -->
                                                    <b-form-radio-group
                                                        id="org_type_id"
                                                        style="margin-top: 6px"
                                                        v-model="profileInfo.professional_info.org_type_id"
                                                        :options="orgTypeList"
                                                        name="org_type_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                    </b-form-radio-group>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-if="profileInfo.professional_info.org_type_id === 3">
                                            <ValidationProvider name="Organization Name (En)" vid="org_name_en" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="org_name_en"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('globalTrans.org_name')}} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        id="org_name_en"
                                                        v-model="profileInfo.professional_info.org_name_en"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-if="profileInfo.professional_info.org_type_id === 3">
                                            <ValidationProvider name="Organization Name (Bn)" vid="org_name_bn" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="org_name_bn"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('globalTrans.org_name')}} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        id="org_name_bn"
                                                        v-model="profileInfo.professional_info.org_name_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-if="profileInfo.professional_info.org_type_id === 1">
                                            <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="org_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('globalTrans.organization')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="profileInfo.professional_info.org_id"
                                                        :options="nominatedMocList"
                                                        id="org_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-if="profileInfo.professional_info.org_type_id === 2">
                                            <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="org_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('globalTrans.organization')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="profileInfo.professional_info.org_id"
                                                        :options="nominatedPrivateList"
                                                        id="org_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-if="profileInfo.professional_info.org_type_id === 1">
                                            <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="office_type_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{$t('elearning_config.office_type')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="profileInfo.professional_info.office_type_id"
                                                        :options="officeTypeList"
                                                        id="office_type_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-if="profileInfo.professional_info.org_type_id === 1">
                                            <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="office_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{$t('globalTrans.office')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="profileInfo.professional_info.office_id"
                                                        :options="officeList"
                                                        id="office_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-if="profileInfo.professional_info.org_type_id === 1">
                                            <ValidationProvider name="Job Type" vid="job_type_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="job_type_id"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                {{ $t('elearning_iabm.job_type')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="profileInfo.professional_info.job_type_id"
                                                    :options="jobTypeList"
                                                    id="job_type_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-if="profileInfo.professional_info.org_type_id === 1">
                                            <ValidationProvider name="Batch" vid="batch" rules="">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="batch"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{ $t('elearning_iabm.batch')}}
                                                    </template>
                                                    <b-form-input
                                                        id="batch"
                                                        v-model="profileInfo.professional_info.batch"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="Designation Name (En)" vid="designation_en" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="designation_en"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('externalTraining.designation')}} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        id="designation_en"
                                                        v-model="profileInfo.professional_info.designation_en"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="Designation Name (Bn)" vid="designation_bn" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="designation_bn"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('externalTraining.designation')}} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        id="designation_bn"
                                                        v-model="profileInfo.professional_info.designation_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="Service Id" vid="service_id" rules="">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="service_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('externalTraining.service_id')}}
                                                    </template>
                                                    <b-form-input
                                                        id="service_id"
                                                        type="number"
                                                        v-model="profileInfo.professional_info.service_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="Pay Grade" vid="pay_grade" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="pay_grade"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('externalTraining.pay_grade')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="profileInfo.professional_info.grade_id"
                                                        :options="gradeList"
                                                        id="pay_grade"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="Controlling Authority (Designation)" vid="controlling_authority">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="controlling_authority"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                    {{ $t('externalTraining.designation_c')}}
                                                </template>
                                                <b-form-input
                                                    id="controlling_authority"
                                                    v-model="profileInfo.professional_info.controlling_authority"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="Telephone" vid='telephone_c' rules="">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="telephone_c"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('externalTraining.telephone_c')}}
                                                    </template>
                                                    <b-form-input
                                                        type="number"
                                                        id="telephone_c"
                                                        v-model="profileInfo.professional_info.telephone"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                        <ValidationProvider name="Email (Controlling Authority)" vid='email_c' rules="email">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="email_c"
                                                slot-scope="{ valid, errors }"
                                            >
                                                <template v-slot:label>
                                                    {{ $t('externalTraining.email_c')}}
                                                </template>
                                                <b-form-input
                                                    id="email_c"
                                                    v-model="profileInfo.professional_info.email"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="Office Email" vid='office_mail' rules="email">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="office_mail"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('externalTraining.office_mail')}}
                                                    </template>
                                                    <b-form-input
                                                        id="nid"
                                                        v-model="profileInfo.professional_info.office_mail_address"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                </template>
                            </body-card>
                            <body-card>
                                <template v-slot:headerTitle>
                                   <h4 class="card-title">{{$t('externalTraining.academic_info')}}</h4>
                                </template>
                                <template v-slot:body>
                                    <b-row>
                                        <b-col sm="4">
                                            <ValidationProvider name="Degree" rules="">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="highest_degree"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('externalTraining.highest_degree')}}
                                                    </template>
                                                    <!-- <b-form-select
                                                        plain
                                                        v-model="profileInfo.name_of_highest_degree"
                                                        :options="degreeList"
                                                        id="highest_degree"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select> -->
                                                    <b-form-input
                                                        id="name_of_highest_degree"
                                                        v-model="profileInfo.name_of_highest_degree"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4">
                                            <ValidationProvider name="University/Board" rules="">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="board_university"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{ $t('externalTraining.board_university')}}
                                                    </template>
                                                    <!-- <b-form-select
                                                        plain
                                                        v-model="profileInfo.board"
                                                        :options="universityList"
                                                        id="board_university"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select> -->
                                                    <b-form-input
                                                        id="board"
                                                        v-model="profileInfo.board"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                </template>
                            </body-card>
                            <body-card>
                                <template v-slot:headerTitle>
                                    <h4 class="card-title">{{$t('externalTraining.present_address')}}</h4>
                                </template>
                                <template v-slot:body>
                                    <b-row class="mb-3">
                                        <b-col sm="4">
                                            <ValidationProvider name="Area Type" vid="pre_area_type_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="area_type_id "
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('globalTrans.area_type')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="profileInfo.address.pre_area_type_id "
                                                    id="area_type_id"
                                                    :options="getAreaTypeList"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-show="ItemShow">
                                            <ValidationProvider name="Division" vid="pre_division_id" rules="">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="pre_division_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('globalTrans.division')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="profileInfo.address.pre_division_id"
                                                    :options="divisionList"
                                                    id="pre_division_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-show="ItemShow">
                                            <ValidationProvider name="District" vid="pre_district_id" rules="">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="pre_district_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('globalTrans.district')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="profileInfo.address.pre_district_id"
                                                    :options="districtList"
                                                    id="pre_district_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-show="PauroshobaItemShow || UnionItemShow">
                                            <ValidationProvider name="Upazila" vid="upazila_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="upazila_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('globalTrans.upazila')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="profileInfo.address.pre_upazilla_id"
                                                    :options="upazilaList"
                                                    id="upazila_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-show="UnionItemShow">
                                            <ValidationProvider name="Union" vid="union_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="union_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('globalTrans.union')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="profileInfo.address.pre_union_id"
                                                    :options="unionList"
                                                    id="union_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-show="CityCorpItemShow">
                                            <ValidationProvider name="City Corporation" vid="city_corporation_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="city_corporation_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('globalTrans.city_corporation') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="profileInfo.address.pre_city_corporation_id"
                                                    :options="cityCorporationList"
                                                    id="city_corporation_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-show="PauroshobaItemShow">
                                            <ValidationProvider name="Municipality" vid="pauroshoba_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="pauroshoba_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('globalTrans.pauroshoba') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="profileInfo.address.pre_pauroshoba_id"
                                                    :options="pauroshobaList"
                                                    id="pauroshoba_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-show="ItemShow">
                                            <ValidationProvider name="Ward" vid="ward_id" rules="">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="ward_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('globalTrans.ward') }}
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="profileInfo.address.pre_ward_id"
                                                    :options="wardList"
                                                    id="ward_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col>
                                            <h5 class="ex_form_title">
                                                <b-form-checkbox
                                                    id="checkbox-1"
                                                    v-model="profileInfo.address.same_as_pre_address"
                                                    name="checkbox-1"
                                                    value="1"
                                                    unchecked-value="0"
                                                    class="checkboxes"
                                                >
                                                    <p class="mb-0" style="color: #3f414d;font-size: 16px;font-weight: 600;">{{ $t('externalTraining.parmanent_address') }} {{ '(' + $t('externalTraining.same_as') + ')' }}</p>
                                                </b-form-checkbox>
                                            </h5>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mb-3">
                                        <b-col sm="4">
                                            <ValidationProvider name="Area Type" vid="area_type_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="area_type_id "
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('globalTrans.area_type')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="profileInfo.address.per_area_type_id "
                                                    id="area_type_id"
                                                    :options="getAreaTypeList"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    :disabled="profileInfo.address.same_as_pre_address === '1'"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-show="ItemShow1">
                                            <ValidationProvider name="Division" vid="division_id" rules="">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="division_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('globalTrans.division')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="profileInfo.address.per_division_id"
                                                    :options="divisionList"
                                                    id="division_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    :disabled="profileInfo.address.same_as_pre_address === '1'"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-show="ItemShow1">
                                            <ValidationProvider name="District" vid="district_id" rules="">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="per_district_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('globalTrans.district')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="profileInfo.address.per_district_id"
                                                    :options="districtList1"
                                                    id="per_district_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    :disabled="profileInfo.address.same_as_pre_address === '1'"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-show="PauroshobaItemShow1 || UnionItemShow1">
                                            <ValidationProvider name="Upazila" vid="upazila_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="upazila_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('globalTrans.upazila')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="profileInfo.address.per_upazilla_id"
                                                    :options="upazilaList1"
                                                    id="upazila_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    :disabled="profileInfo.address.same_as_pre_address === '1'"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-show="UnionItemShow1">
                                            <ValidationProvider name="Union" vid="union_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="union_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('globalTrans.union')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="profileInfo.address.per_union_id"
                                                    :options="unionList1"
                                                    id="union_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    :disabled="profileInfo.address.same_as_pre_address === '1'"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-show="CityCorpItemShow1">
                                            <ValidationProvider name="City Corporation" vid="city_corporation_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="city_corporation_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('globalTrans.city_corporation') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="profileInfo.address.per_city_corporation_id"
                                                    :options="cityCorporationList1"
                                                    id="city_corporation_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    :disabled="profileInfo.address.same_as_pre_address === '1'"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-show="PauroshobaItemShow1">
                                            <ValidationProvider name="Municipality" vid="pauroshoba_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="pauroshoba_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('globalTrans.pauroshoba') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="profileInfo.address.per_pauroshoba_id"
                                                    :options="pauroshobaList1"
                                                    id="pauroshoba_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    :disabled="profileInfo.address.same_as_pre_address === '1'"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="4" v-show="ItemShow1">
                                            <ValidationProvider name="Ward" vid="ward_id" rules="">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="ward_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('globalTrans.ward') }}
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="profileInfo.address.per_ward_id"
                                                    :options="wardList1"
                                                    id="ward_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    :disabled="profileInfo.address.same_as_pre_address === '1'"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                </template>
                            </body-card>
                            <div class="text-right mb-3">
                                <b-button-group>
                                    <b-button type="submit"
                                        :disabled="saved"
                                        variant="success"
                                        title="Final Save cannot be reverted!"
                                        class="ml-1 rounded">{{ $t('globalTrans.finalSave') }}
                                        <i class="fas fa-lock ml-1"></i>
                                    </b-button>
                                    <!-- <b-button type="submit"
                                        variant="danger"
                                        title="Final Save cannot be reverted!"
                                        class="ml-1 rounded">{{ $t('externalUserIrrigation.final_save') }}
                                        <i class="fas fa-lock ml-1"></i>
                                    </b-button> -->
                                </b-button-group>
                            </div>
                        </b-form>
                    </ValidationObserver>
                </b-overlay>
            </template>
        </card>
        <b-modal hide-footer id="modal-5" size="lg" :title="$t('externalTraining.profile_info') + ' ' + this.$t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <b-button @click="pdfExportDetails" class="ml-4 btn-success water-test-pdf-button">
                {{  $t('globalTrans.export_pdf') }}
            </b-button>
            <Details ref="details"/>
        </b-modal>
    </div>
</template>
<style>
.checkboxes label.custom-control-label {
    color: white !important;
    font-size: 13px !important;
    font-weight: bold !important;
}
</style>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { profileStore, profileInfo } from '../../api/routes'
import flatpickr from 'flatpickr'
import Details from './Details'
import { mapGetters } from 'vuex'
import { EventBus } from '@/EventBusLayout'

export default {
    components: {
        Details
    },
    created () {
        this.loading = true
        RestApi.getData(trainingElearningServiceBaseUrl, `${profileInfo}`).then(response => {
            if (response.success) {
                if (response.data.status === 3) {
                    this.$router.push('/training-panel/professional-profile')
                }
                this.saved = true
                this.profileInfo = response.data
                this.profileInfo.image = ''
                this.ItemShow = true
                const typeId = this.profileInfo.address.pre_area_type_id
                if (typeId === 1) {
                    this.CityCorpItemShow = true
                    this.PauroshobaItemShow = false
                    this.UnionItemShow = false
                } else if (typeId === 2) {
                    this.CityCorpItemShow = false
                    this.PauroshobaItemShow = true
                    this.UnionItemShow = false
                } else if (typeId === 3) {
                    this.CityCorpItemShow = false
                    this.PauroshobaItemShow = false
                    this.UnionItemShow = true
                }
                this.ItemShow1 = true
                if (typeId === 1) {
                    this.CityCorpItemShow1 = true
                    this.PauroshobaItemShow1 = false
                    this.UnionItemShow1 = false
                } else if (typeId === 2) {
                    this.CityCorpItemShow1 = false
                    this.PauroshobaItemShow1 = true
                    this.UnionItemShow1 = false
                } else if (typeId === 3) {
                    this.CityCorpItemShow1 = false
                    this.PauroshobaItemShow1 = false
                    this.UnionItemShow1 = true
                }
                this.overlay = false
            } else {
                this.saved = false
                this.profileInfo.name = this.commonProfile.name
                this.profileInfo.name_bn = this.commonProfile.name_bn
                this.profileInfo.email = this.commonProfile.email
                this.profileInfo.mobile = this.commonProfile.mobile
                if (!this.commonProfile.mobile) {
                    this.profileInfo.mobile = this.$store.state.Auth.authUser.mobile
                }
                // this.profileInfo.address.pre_area_type_id = 3
                this.profileInfo.address.pre_area_type_id = this.commonProfile.pr_area_type_id
                this.profileInfo.address.pre_division_id = this.commonProfile.pr_division_id
                this.profileInfo.address.pre_district_id = this.commonProfile.pr_district_id
                this.profileInfo.address.pre_upazilla_id = this.commonProfile.pr_upazila_id
                this.profileInfo.address.pre_union_id = this.commonProfile.pr_union_id
                // this.profileInfo.address.per_area_type_id = 3
                this.profileInfo.address.per_area_type_id = this.commonProfile.pm_area_type_id
                this.profileInfo.address.per_division_id = this.commonProfile.pm_division_id
                this.profileInfo.address.per_district_id = this.commonProfile.pm_district_id
                this.profileInfo.address.per_upazilla_id = this.commonProfile.pm_upazila_id
                this.profileInfo.address.per_union_id = this.commonProfile.pm_union_id
                this.profileInfo.gender = this.commonProfile.gender
                this.profileInfo.nid = this.commonProfile.nid_no
                this.overlay = true
            }
            this.loading = false
        })
    },
    data () {
        return {
            clickCount: 0,
            ItemShow: false,
            CityCorpItemShow: false,
            PauroshobaItemShow: false,
            UnionItemShow: false,
            ItemShow1: false,
            CityCorpItemShow1: false,
            PauroshobaItemShow1: false,
            UnionItemShow1: false,
            overlay: false,
            profileInfo: {
                name: '',
                name_bn: '',
                datee_of_birth: '',
                email: '',
                nid: '',
                mobile: '',
                gender: 0,
                marital_status: 0,
                blood_group: 0,
                religion: 0,
                emergency_contact: '',
                name_of_highest_degree: '',
                board: '',
                image_file: [],
                signature_file: [],
                professional_info: {
                    org_name_en: '',
                    org_name_bn: '',
                    org_type_id: 0,
                    org_id: 0,
                    job_type_id: 0,
                    designation_en: '',
                    designation_bn: '',
                    office_type_id: 0,
                    office_id: 0,
                    grade_id: 0,
                    service_id: '',
                    batch: '',
                    controlling_authority: '',
                    telephone: '',
                    email: '',
                    office_mail_address: ''
                },
                address: {
                    pre_area_type_id: 0,
                    pre_division_id: 0,
                    pre_district_id: 0,
                    pre_upazilla_id: 0,
                    pre_ward_id: '',
                    pre_union_id: '',
                    pre_city_corporation_id: '',
                    pre_pauroshoba_id: '',
                    same_as_pre_address: '',
                    per_area_type_id: 0,
                    per_division_id: 0,
                    per_district_id: 0,
                    per_upazilla_id: 0,
                    per_city_corporation_id: '',
                    per_pauroshoba_id: '',
                    per_ward_id: '',
                    per_union_id: ''
                },
                is_draft: 1,
                status: 1
            },
            saved: false,
            officeTypeList: [],
            officeList: [],
            districtList: [],
            upazilaList: [],
            unionList: [],
            wardList: [],
            cityCorporationList: [],
            pauroshobaList: [],
            districtList1: [],
            upazilaList1: [],
            unionList1: [],
            wardList1: [],
            cityCorporationList1: [],
            pauroshobaList1: [],
            zoneList: [],
            unitList: [],
            designationList: [],
            id: '',
            image: [],
            orgType: [
                {
                    value: 1,
                    text: 'MOC',
                    text_en: 'MOC',
                    text_bn: 'এমওসি'
                },
                {
                    value: 2,
                    text: 'Private',
                    text_en: 'Private',
                    text_bn: 'ব্যক্তিগত'
                },
                {
                    value: 3,
                    text: 'Others',
                    text_en: 'Others',
                    text_bn: 'অন্যান্য'
                }
            ],
            loading: false
        }
    },
    mounted () {
        flatpickr('.fromDate', {})
    },
    computed: {
        ...mapGetters({
        authUser: 'Auth/authUser'
        }),
        orgTypeList: function () {
        // return this.orgType
        return this.orgType.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
        },
        userTypeList () {
            const userList = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Trainer' : 'প্রশিক্ষক' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী' }
            ]
            return userList
        },
        nominatedMocList () {
            return this.$store.state.ExternalUserService.trainingPanel.commonObj.nominatedOrgList.filter(item => item.status === 1 && item.org_type_id === 1)
        },
        nominatedPrivateList () {
            return this.$store.state.ExternalUserService.trainingPanel.commonObj.nominatedOrgList.filter(item => item.status === 1 && item.org_type_id === 2)
        },
        jobTypeList () {
            return this.$store.state.ExternalUserService.trainingPanel.commonObj.jobTypeList
        },
        getAreaTypeList: function () {
            const objectData = this.$store.state.commonObj.areaTypeList
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        },
        divisionList: function () {
            return this.$store.state.CommonService.commonObj.divisionList.filter(item => item.status === 1)
        },
        genderList () {
            const gender = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Male' : 'পুরুষ' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Female' : 'মহিলা' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'Others' : 'অন্যান্য' }
            ]
            return gender
        },
        maritalList () {
            const status = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Married' : 'বিবাহিত' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Unmarried' : 'অবিবাহিত' }
            ]
            return status
        },
        bloodGroupList () {
            const group = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'A+' : 'এ+' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'A-' : 'এ-' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'B+' : 'বি+' },
                { value: 4, text: this.$i18n.locale === 'en' ? 'B-' : 'বি-' },
                { value: 5, text: this.$i18n.locale === 'en' ? 'O+' : 'ও+' },
                { value: 6, text: this.$i18n.locale === 'en' ? 'O-' : 'ও-' },
                { value: 7, text: this.$i18n.locale === 'en' ? 'AB+' : 'এবি+' },
                { value: 8, text: this.$i18n.locale === 'en' ? 'AB-' : 'এবি-' }
            ]
            return group
        },
        religionList () {
           const gender = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Muslim' : 'মুসলিম' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Hindu' : 'হিন্দু' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'Christian' : 'খ্রিস্টান' },
                { value: 4, text: this.$i18n.locale === 'en' ? 'Buddhist' : 'বৌদ্ধ ' },
                { value: 5, text: this.$i18n.locale === 'en' ? 'Others' : 'অন্যান্য' }
            ]
            return gender
        },
        serviceList () {
            return null
        },
        degreeList () {
            return null
        },
        universityList () {
            return null
        },
        gradeList: function () {
            // const grade = [
            //     { value: 1, text: this.$i18n.locale === 'en' ? 'Grade-1' : 'গ্রেড-' + this.$n(1) },
            //     { value: 2, text: this.$i18n.locale === 'en' ? 'Grade-2' : 'গ্রেড-' + this.$n(2) },
            //     { value: 3, text: this.$i18n.locale === 'en' ? 'Grade-3' : 'গ্রেড-' + this.$n(3) },
            //     { value: 4, text: this.$i18n.locale === 'en' ? 'Grade-4' : 'গ্রেড-' + this.$n(4) },
            //     { value: 5, text: this.$i18n.locale === 'en' ? 'Grade-5' : 'গ্রেড-' + this.$n(5) },
            //     { value: 6, text: this.$i18n.locale === 'en' ? 'Grade-6' : 'গ্রেড-' + this.$n(6) },
            //     { value: 7, text: this.$i18n.locale === 'en' ? 'Grade-7' : 'গ্রেড-' + this.$n(7) },
            //     { value: 8, text: this.$i18n.locale === 'en' ? 'Grade-8' : 'গ্রেড-' + this.$n(8) },
            //     { value: 9, text: this.$i18n.locale === 'en' ? 'Grade-9' : 'গ্রেড-' + this.$n(9) },
            //     { value: 10, text: this.$i18n.locale === 'en' ? 'Grade-10' : 'গ্রেড-' + this.$n(10) },
            //     { value: 11, text: this.$i18n.locale === 'en' ? 'Grade-11' : 'গ্রেড-' + this.$n(11) },
            //     { value: 12, text: this.$i18n.locale === 'en' ? 'Grade-12' : 'গ্রেড-' + this.$n(12) },
            //     { value: 13, text: this.$i18n.locale === 'en' ? 'Grade-13' : 'গ্রেড-' + this.$n(13) },
            //     { value: 14, text: this.$i18n.locale === 'en' ? 'Grade-14' : 'গ্রেড-' + this.$n(14) },
            //     { value: 15, text: this.$i18n.locale === 'en' ? 'Grade-15' : 'গ্রেড-' + this.$n(15) },
            //     { value: 16, text: this.$i18n.locale === 'en' ? 'Grade-16' : 'গ্রেড-' + this.$n(16) },
            //     { value: 17, text: this.$i18n.locale === 'en' ? 'Grade-17' : 'গ্রেড-' + this.$n(17) },
            //     { value: 18, text: this.$i18n.locale === 'en' ? 'Grade-18' : 'গ্রেড-' + this.$n(18) },
            //     { value: 19, text: this.$i18n.locale === 'en' ? 'Grade-19' : 'গ্রেড-' + this.$n(19) },
            //     { value: 20, text: this.$i18n.locale === 'en' ? 'Grade-20' : 'গ্রেড-' + this.$n(20) }
            // ]
            return this.$store.state.commonObj.gradeList.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        },
        commonProfile () {
            return this.$store.state.Auth.commonProfile
        }
    },
    watch: {
        'profileInfo.address.pre_area_type_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getAreaTypeData(this.profileInfo.address.pre_area_type_id)
            }
        },
        'profileInfo.address.per_area_type_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getAreaTypeData1(this.profileInfo.address.per_area_type_id)
            }
        },
        'profileInfo.address.pre_division_id': function (newVal, oldVal) {
            this.districtList = this.getDistrictList(newVal)
        },
        'profileInfo.address.pre_district_id': function (newVal, oldVal) {
            this.cityCorporationList = this.getCityCorporationList(newVal)
            this.upazilaList = this.getUpazilaList(newVal)
        },
        'profileInfo.address.pre_upazilla_id': function (newVal, oldVal) {
            this.unionList = this.getUnionList(newVal)
            this.pauroshobaList = this.getPauroshobaList(newVal)
        },
        'profileInfo.address.pre_city_corporation_id': function (newVal, oldVal) {
            this.wardList = this.getWardListByCityCorportaion(newVal)
        },
        'profileInfo.address.pre_pauroshoba_id': function (newVal, oldVal) {
            this.wardList = this.getWardListByPauroshoba(newVal)
        },
        'profileInfo.address.pre_union_id': function (newVal, oldVal) {
            this.wardList = this.getWardListByUnion(newVal)
        },
        'profileInfo.address.per_division_id': function (newVal, oldVal) {
            this.districtList1 = this.getDistrictList(newVal)
        },
        'profileInfo.address.per_district_id': function (newVal, oldVal) {
            this.cityCorporationList1 = this.getCityCorporationList(newVal)
            this.upazilaList1 = this.getUpazilaList(newVal)
        },
        'profileInfo.address.per_upazilla_id': function (newVal, oldVal) {
            this.unionList1 = this.getUnionList(newVal)
            this.pauroshobaList1 = this.getPauroshobaList(newVal)
        },
        'profileInfo.address.per_city_corporation_id': function (newVal, oldVal) {
            this.wardList1 = this.getWardListByCityCorportaion(newVal)
        },
        'profileInfo.address.per_pauroshoba_id': function (newVal, oldVal) {
            this.wardList1 = this.getWardListByPauroshoba(newVal)
        },
        'profileInfo.address.per_union_id': function (newVal, oldVal) {
            this.wardList1 = this.getWardListByUnion(newVal)
        },
        'profileInfo.professional_info.org_id': function (newVal, oldVal) {
            const orgData = this.$store.state.ExternalUserService.trainingPanel.commonObj.nominatedOrgList.find(item => item.value === newVal)
            this.designationList = this.getDesignationByOrg(orgData.org_id)
            this.officeTypeList = this.getOfficeTypeList(orgData.org_id)
        },
        'profileInfo.professional_info.office_type_id': function (newValue) {
            this.officeList = this.getParentOfficeList(newValue)
        },
        'profileInfo.address.same_as_pre_address': function (newVal, oldVal) {
            if (newVal === '1') {
                this.profileInfo.address.per_area_type_id = this.profileInfo.address.pre_area_type_id
                this.profileInfo.address.per_division_id = this.profileInfo.address.pre_division_id
                this.profileInfo.address.per_district_id = this.profileInfo.address.pre_district_id
                this.profileInfo.address.per_upazilla_id = this.profileInfo.address.pre_upazilla_id
                this.profileInfo.address.per_city_corporation_id = this.profileInfo.address.pre_city_corporation_id
                this.profileInfo.address.per_pauroshoba_id = this.profileInfo.address.pre_pauroshoba_id
                this.profileInfo.address.per_ward_id = this.profileInfo.address.pre_ward_id
                this.profileInfo.address.per_union_id = this.profileInfo.address.pre_union_id
                this.getAreaTypeData1(this.profileInfo.address.pre_area_type_id)
            }
        }

    },
    methods: {
        handleLeftPhoto (e) {
            const selectedPhoto = e.target.files[0]
            const reader = new FileReader()
            reader.onload = (event) => {
                this.profileInfo.image = event.target.result
            }
            reader.readAsDataURL(selectedPhoto)
        },
        handleRightPhoto (e) {
            const selectedPhoto = e.target.files[0]
            const reader = new FileReader()
            reader.onload = (event) => {
                this.profileInfo.signature = event.target.result
            }
            reader.readAsDataURL(selectedPhoto)
        },
        onChangeFile (e) {
            this.profileInfo.image_file = e.target.files[0]
        },
        getAreaTypeData (typeId) {
            this.ItemShow = true
            if (typeId === 1) {
                this.CityCorpItemShow = true
                this.PauroshobaItemShow = false
                this.UnionItemShow = false
            } else if (typeId === 2) {
                this.CityCorpItemShow = false
                this.PauroshobaItemShow = true
                this.UnionItemShow = false
            } else if (typeId === 3) {
                this.CityCorpItemShow = false
                this.PauroshobaItemShow = false
                this.UnionItemShow = true
            }
        },
        getAreaTypeData1 (typeId) {
            this.ItemShow1 = true
            if (typeId === 1) {
                this.CityCorpItemShow1 = true
                this.PauroshobaItemShow1 = false
                this.UnionItemShow1 = false
            } else if (typeId === 2) {
                this.CityCorpItemShow1 = false
                this.PauroshobaItemShow1 = true
                this.UnionItemShow1 = false
            } else if (typeId === 3) {
                this.CityCorpItemShow1 = false
                this.PauroshobaItemShow1 = false
                this.UnionItemShow1 = true
            }
        },
        async register () {
            this.loading = true
            let result = null
            var formData = new FormData()
            Object.keys(this.profileInfo).map(key => {
                if (key === 'image_file') {
                    formData.append('image', this.profileInfo.image_file)
                } else if (key === 'signature_file') {
                    formData.append('signature', this.profileInfo.signature_file)
                } else {
                    formData.append(key, this.profileInfo[key])
                }
            })
            formData.append('address', JSON.stringify(this.profileInfo.address))
            formData.append('professional_info', JSON.stringify(this.profileInfo.professional_info))
            result = await RestApi.postData(trainingElearningServiceBaseUrl, `${profileStore}`, formData)
            if (result.success) {
                this.$toast.success({
                    title: 'Success',
                    message: this.$t('externalTraining.please_wait_for_admin_approval'),
                    color: '#D6E09B'
                })
                EventBus.$emit('menuUpdate')
                // this.$socket.emit('send-notification', result.notification)
                this.$router.push('/training-panel/dashboard')
            } else {
                this.$toast.error({
                    title: 'Error',
                    message: 'Operation failed! Please, try again.'
                })
                this.$refs.form.setErrors(result.errors)
            }
           this.loading = false
        },
        getDistrictList (divisionId = null) {
            const districtList = this.$store.state.CommonService.commonObj.districtList.filter(item => item.status === 1)

            if (divisionId) {
                return districtList.filter(district => district.division_id === divisionId)
            }

            return districtList
        },
        getUpazilaList (districtId = null) {
            const upazilaList = this.$store.state.CommonService.commonObj.upazilaList.filter(item => item.status === 1)

            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }

            return upazilaList
        },
        getUnionList (upazilaId = null) {
            const unionList = this.$store.state.CommonService.commonObj.unionList.filter(item => item.status === 1)
            if (upazilaId) {
                return unionList.filter(union => union.upazila_id === upazilaId)
            }

            return unionList
        },
        getOfficeTypeList (orgId = null) {
            const officeTypeList = this.$store.state.CommonService.commonObj.officeTypeList.filter(item => item.status === 1)
            if (orgId) {
                return officeTypeList.filter(office => office.org_id === orgId).map(obj => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text }
                    }
                })
            }
            return officeTypeList
        },
        getParentOfficeList (officeTypeId = null) {
            const officeList = this.$store.state.CommonService.commonObj.officeList.filter(item => item.status === 1)
            if (officeTypeId) {
                return officeList.filter(office => office.office_type_id === officeTypeId)
            }
            return officeList
        },
        getCityCorporationList (districtId) {
            const objectData = this.$store.state.CommonService.commonObj.cityCorporationList.filter(item => item.status === 1 && item.district_id === districtId)
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        },
        getPauroshobaList (upazillaId = null) {
            const objectData = this.$store.state.CommonService.commonObj.municipalityList.filter(item => item.upazila_id === parseFloat(upazillaId))
            if (upazillaId) {
                return objectData.map((obj, key) => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text_en }
                    }
                })
            }
        },
        getWardListByCityCorportaion (cityCorpId) {
            const objectData = this.$store.state.CommonService.commonObj.wardList
            const wardObjectList = objectData.filter(item => item.city_corporation_id === cityCorpId && item.status === 1)
            return wardObjectList.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        },
        getWardListByPauroshoba (pauroshobaId) {
            const objectData = this.$store.state.CommonService.commonObj.wardList
            const wardObjectList = objectData.filter(item => item.pauroshoba_id === pauroshobaId && item.status === 1)
            return wardObjectList.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        },
        getWardListByUnion (unionId) {
            const objectData = this.$store.state.CommonService.commonObj.wardList
            const wardObjectList = objectData.filter(item => item.union_id === unionId && item.status === 1)
            return wardObjectList.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        },
        getUnitList (zoneId = null) {
            const unitList = this.$store.state.agriMarketing.unitList
            if (unitList) {
                return unitList.filter(item => item.zone_id === zoneId && item.status === 1)
            }
            return unitList
        },
        getDesignationByOrg (orgId = null) {
            const desigList = this.$store.state.CommonService.commonObj.designationList
            if (desigList) {
                return desigList.filter(item => item.org_id === orgId)
            }
            return desigList
        },
        pdfExportDetails () {
            this.$refs.details.pdfExport()
        }
    }
}
</script>
